<script setup>
import { ref, defineProps, defineEmits, computed, defineExpose, watchEffect } from 'vue';
import AppDeleteDialog from '@/components/AppDeleteDialog.vue';
import { getClientBase } from '@/services/http';
import { getClientSesmt } from '@/services/http-sesmt';
import { useAlert } from '@/composables/useAlert';
import axios from 'axios';
import { useGlobalFilters } from '../../../utils/useGlobalFilters';
import { limitarCaracteres } from '../../../services/auth';

const { alertError, alertSucess } = useAlert();

const props = defineProps({
    origem: {
        type: String,
        required: true
    },
    origemId: {
        type: Number,
        required: true,
        default: 0
    },
    tipo: {
        type: String,
        required: true
    },
    habilitarAnexoTemporario: {
        type: Boolean,
        default: false
    }
});
const emit = defineEmits(['onUpload', 'onDelete']);

const anexos = ref([]);
const record = ref();
const loading = ref(false);
const showAppDeleteDialog = ref(false);
const showRemoveAnexoDialog = ref(false);

watchEffect(loadAnexos);

async function loadAnexos() {
    try {
        if (props.habilitarAnexoTemporario || !props.origemId) return;

        loading.value = true;
        const response = await getClientBase().get(`/anexo/${props.origem}/` + props.origemId);
        anexos.value = response.data;
    } catch (error) {
        alertError('Problemas ao listar.');
    } finally {
        loading.value = false;
    }
}

function downloadAnexo(data) {
    axios({
        url: data.url,
        method: 'GET',
        responseType: 'blob'
    }).then((res) => {
        const file = window.URL.createObjectURL(new Blob([res.data]));

        const docUrl = document.createElement('a');
        docUrl.href = file;
        docUrl.setAttribute('download', data?.nomeArquivo);
        document.body.appendChild(docUrl);
        docUrl.click();
    });
}

const loadingAnexoTemporario = ref(false);
const anexosTemporarios = ref([]);
const isAnexoTemporario = computed(() => !props.origemId && props.habilitarAnexoTemporario && anexosTemporarios.value.length);
const { formatDateOnly } = useGlobalFilters();

async function uploadAnexo(event) {
    const formData = new FormData();
    formData.append('file', event.files[0]);
    formData.append('origem', props.origem);
    formData.append('origemId', props.origemId);
    formData.append('tipo', props.tipo);

    if (props.habilitarAnexoTemporario && !props.origemId) {
        await uploadAnexoTemporario(event.files[0], formData);
        return;
    }

    try {
        loading.value = true;
        await getClientBase().post('/anexo', formData);
        await loadAnexos();
        alertSucess('Arquivo carregado com sucesso');
    } catch (err) {
        alertError('Problemas ao carregar o arquivo');
    } finally {
        loading.value = false;
    }
}

async function uploadAnexoTemporario(file, formData) {
    try {
        loadingAnexoTemporario.value = true;
        const response = await getClientSesmt().post('/anexo/upload-anexo-temporario', formData);
        anexosTemporarios.value.push({
            nome: file.name,
            data: formatDateOnly(file.lastModifiedDate),
            key: response.data.key,
            url: response.data.url
        });
    } catch (error) {
        alertError('Problemas ao carregar o arquivo');
    } finally {
        loadingAnexoTemporario.value = false;
    }
}

function onClickRemoveAnexoTemporario() {
    anexosTemporarios.value.splice(record.value, 1);
    showRemoveAnexoDialog.value = false;
}

function onClickCloseRemoveAnexoTemporario() {
    showRemoveAnexoDialog.value = false;
}

function onClickConfirmRemoveAnexoTemporario(records) {
    record.value = records;
    showRemoveAnexoDialog.value = true;
}

function onClickConfirmDeleteRecord(records) {
    record.value = records;
    showAppDeleteDialog.value = true;
}
function onClickCloseDeleteRecord() {
    showAppDeleteDialog.value = false;
}

async function onClickDeleteRecord() {
    if (!record.value) {
        return;
    }

    try {
        await getClientBase().delete(`/anexo/${record.value.id}`);
        record.value = {};
        emit('onDelete');
        await loadAnexos();
        onClickCloseDeleteRecord();
        alertSucess('Anexo excluído com sucesso!');
    } catch (err) {
        alertError('Erro ao excluir o anexo!');
    }
}

defineExpose({ anexosTemporarios });
</script>
<template>
    <div>
        <FileUpload
            :disabled="props.habilitarAnexoTemporario && loadingAnexoTemporario"
            mode="basic"
            auto
            :maxFileSize="10485760"
            :showCancelButton="false"
            :showUploadButton="false"
            invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb."
            chooseLabel="Anexar arquivos"
            type="button"
            class="p-button p-button-outlined mb-3"
            customUpload
            @uploader="uploadAnexo"
        />
        <DataTable
            v-if="!loading && anexos.length && !isAnexoTemporario"
            dataKey="id"
            :value="anexos"
            :row-hover="true"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
        >
            <template #empty> Nenhum registro encontrado. </template>

            <Column field="nomeArquivo" header="Nome">
                <template #body="{ data }">
                    <a :href="data.url" class="white-space-nowrap overflow-hidden text-overflow-ellipsis" style="max-width: 60vw" target="_blank">
                        {{ limitarCaracteres(data?.nomeArquivo, 40) }}
                    </a>
                </template>
            </Column>
            <Column field="tipo" header="Tipo"></Column>
            <Column field="createdAt" header="Data">
                <template #body="{ data }">
                    {{ $filters.formatDateOnly(data.createdAt) }}
                </template>
            </Column>
            <Column :exportable="false" style="min-width: 2rem">
                <template #body="slotProps">
                    <Button icon="pi pi-cloud-download" class="p-button-text p-button-secondary" @click="downloadAnexo(slotProps.data)" />
                </template>
            </Column>
            <Column :exportable="false" style="min-width: 2rem">
                <template #body="slotProps">
                    <Button icon="pi pi-trash" class="p-button-text p-button-secondary" @click="onClickConfirmDeleteRecord(slotProps.data)" />
                </template>
            </Column>
        </DataTable>

        <DataTable
            v-if="isAnexoTemporario"
            :value="anexosTemporarios"
            :row-hover="true"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
        >
            <Column field="nome" header="Nome">
                <template #body="{ data }">
                    {{ limitarCaracteres(data?.nome, 40) }}
                </template>
            </Column>
            <Column field="data" header="Data" />
            <Column>
                <template #body="{ data, index }">
                    <Button icon="pi pi-trash" class="p-button-text p-button-secondary" @click="onClickConfirmRemoveAnexoTemporario(data, index)" />
                </template>
            </Column>
        </DataTable>
        <AppDeleteDialog
            v-if="record?.nomeArquivo"
            v-model:visible="showAppDeleteDialog"
            v-model:title="record.nomeArquivo"
            @onConfirm="onClickDeleteRecord"
            @onClose="onClickCloseDeleteRecord"
        >
        </AppDeleteDialog>

        <AppDeleteDialog
            v-if="record?.nome"
            v-model:visible="showRemoveAnexoDialog"
            v-model:title="record.nome"
            @onConfirm="onClickRemoveAnexoTemporario"
            @onClose="onClickCloseRemoveAnexoTemporario"
        >
        </AppDeleteDialog>
    </div>
</template>
<style lang="scss" scoped>
.anexo-aviso {
    font-size: 13px;
    height: auto;
    color: #b6bfc7;
}

.icon-aviso {
    font-size: 10rem;
    color: #ced4da;
}
</style>
