const TipoAnexoEnum = Object.freeze({
    LOGO: 'LOGO',
    MANUAL: 'MANUAL',
    CHECKLIST_INICIAL: 'CHECKLIST_INICIAL',
    CHECKLIST_AMBIENTE: 'CHECKLIST_AMBIENTE',
    CHECKLIST_PERIGO: 'CHECKLIST_PERIGO',
    CHECKLIST_PERIGO_FONTE_GERADORA_MEDICAO: 'CHECKLIST_PERIGO_FONTE_GERADORA_MEDICAO',
    CHECKLIST_PERIGO_MEDIDA_CONTROLE_EXISTENTE: 'CHECKLIST_PERIGO_MEDIDA_CONTROLE_EXISTENTE',
    APLICACAO_LEVANTAMENTO_CONCLUSAO: 'APLICACAO_LEVANTAMENTO_CONCLUSAO',
    RELATORIO: 'RELATORIO'
});

export default TipoAnexoEnum;
