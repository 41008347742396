export const StatusRegistroPendencia = Object.freeze({
    PENDENTE: 'PENDENTE',
    EMAIL_ENVIADO: 'E-MAIL ENVIADO',
    PENDENCIA_RESOLVIDA: 'PENDÊNCIA RESOLVIDA'
});

export const TipoUsuarioRegistrarPendenciaEnum = Object.freeze({
    INTERNO: 'INTERNO',
    EXTERNO: 'EXTERNO'
});
