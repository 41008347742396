import { useToast } from 'primevue/usetoast';

export const useAlert = () => {
    const { add } = useToast();

    const alertSucess = (detail, summary = '', life = 3000) => add({ severity: 'success', summary: summary, detail: detail, life: life });

    const alertError = (error, summary = '', life = 3000) => add({ severity: 'error', summary: summary, detail: error, life: life });

    const alertWarn = (detail, summary = '', life = 3000) => add({ severity: 'warn', summary: summary, detail: detail, life: life });

    const alertInfo = (detail, summary = '', life = 3000) => add({ severity: 'info', summary: summary, detail: detail, life: life });

    const alertErrorData = (error, customMessage = 'Ocorreu algum problema', life = 3000) => {
        const detailsMessages = error?.response?.data?.details?.response?.message;
        const messages = Array.isArray(detailsMessages) ? detailsMessages.join() : detailsMessages;
        const message = messages || error?.response?.data?.message || error.message;

        add({
            severity: 'error',
            summary: customMessage,
            detail: message,
            life
        });
    };
    return {
        alertSucess,
        alertError,
        alertWarn,
        alertErrorData,
        alertInfo
    };
};
